import {
  CCreateElement,
  CSidebar,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavDropdown,
  CSidebarNavItem,
  CSidebarNavTitle,
  CSidebarFooter,
  CSpinner,
} from '@coreui/react';
import React, { ReactElement, useState, useRef, useEffect } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useSideBar } from '../../navigation/_sidebar';
import useLogout from '../../library/hooks/user/useLogout';
import { setSidebarDisplay } from '../../store/setting/actions';
import useRiskified, { RISKIFIED_ACTION } from '../../services/gateways/riskified/useRiskified';
import ArrowLeft from '../../assets/images/misc-icons/arrow-left.png';

export default function SideBar(): ReactElement {
  const { sideBar } = useSideBar();
  const dispatch = useDispatch();
  const { logoutUserAndDevice } = useLogout();
  const { riskifiedLogPage } = useRiskified();
  const [isLoading, setIsLoading] = useState(false);
  const show = useSelector((state: RootStateOrAny) => state.setting.sidebarDisplay);
  const mounted = useRef<boolean>();
  const pureSideBarValues = [...sideBar];
  const hasExclusiveRate = useSelector((state: RootStateOrAny) => state.user?.user?.account?.has_exclusive_rate);
  if (hasExclusiveRate) {
    pureSideBarValues.push({
      _tag: 'CSidebarNavItem',
      name: 'Exclusive rates',
      to: '/exclusive-rates',
    });
  }
  function toggleSidebar() {
    const val: boolean | string = [true, 'responsive'].includes(show) ? false : 'responsive';
    dispatch(setSidebarDisplay(val));
  }

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  async function logoutUser() {
    try {
      setIsLoading(true);
      riskifiedLogPage(RISKIFIED_ACTION.LOGOUT);
      await logoutUserAndDevice();
    } finally {
      if (mounted.current) {
        setIsLoading(false);
      }
    }
  }

  return (
    <CSidebar
      colorScheme="light"
      show={show}
      onShowChange={(val: boolean | string) => dispatch(setSidebarDisplay(val))}
      dropdownMode="openActive"
    >
      <CSidebarNav>
        <CCreateElement
          items={pureSideBarValues}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle,
          }}
        />
      </CSidebarNav>
      <div style={{ height: 60, ...(isLoading ? { textAlign: 'center' } : {}), cursor: 'pointer', marginBottom: 32 }}>
        {isLoading ? (
          <CSpinner size="sm" />
        ) : (
          <a className="c-sidebar-nav-link p-regular" onClick={logoutUser}>
            Logout
          </a>
        )}
      </div>
      <CSidebarFooter className="text-right">
        <span className="btn-clear text-right" style={{ cursor: 'pointer' }} onClick={toggleSidebar}>
          <img src={ArrowLeft} />
        </span>
      </CSidebarFooter>
    </CSidebar>
  );
}
