import React, { ReactElement } from 'react';
import fluzLogo from '../../assets/images/fluz-icons/fluz-logo-full.svg';
import ProfileCard from '../../views/user/ProfileCard';
export default function Header(): ReactElement {
  return (
    <>
      <a href="/">
        <img height="29" src={fluzLogo} />
      </a>
      <ProfileCard />
    </>
  );
}
