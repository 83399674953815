import { ActionType } from 'typesafe-actions';

import * as setting from './actions';
import { SET_SIDEBAR_DISPLAY } from './constants';

export type settingAction = ActionType<typeof setting>;

export type SettingState = {
  sidebarDisplay: boolean | string;
};

const defaultState: SettingState = {
  sidebarDisplay: 'responsive',
};

export default (state = defaultState, action: settingAction): SettingState => {
  switch (action.type) {
    case SET_SIDEBAR_DISPLAY:
      return {
        ...state,
        sidebarDisplay: action.payload,
      };
    default:
      return state;
  }
};
