import React, { ComponentProps } from 'react';
import { AnimatedSwitch, spring } from 'react-router-transition';

export default function SwitchTransition(props: ComponentProps<any>) {
  function mapStyles(styles: { opacity: number; scale: number }) {
    return {
      opacity: styles.opacity,
    };
  }

  // wrap the `spring` helper to use a bouncy config
  function bounce(val: number) {
    return spring(val, {
      stiffness: 330,
      damping: 22,
    });
  }

  const bounceTransition = {
    atEnter: {
      opacity: 0,
    },
    atLeave: {
      opacity: bounce(0),
    },
    atActive: {
      opacity: bounce(1),
    },
  };

  return (
    <AnimatedSwitch
      atEnter={bounceTransition.atEnter}
      atLeave={bounceTransition.atLeave}
      atActive={bounceTransition.atActive}
      mapStyles={mapStyles}
      className="route-wrapper"
    >
      {props.children}
    </AnimatedSwitch>
  );
}
