import { CSpinner } from '@coreui/react';
import React, { ReactElement } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import Avatar from '../../components/Avatar';
import Label from '../../components/common/label/Label';
import useUserProfile from '../../library/hooks/user/useUserProfile';
import { displayCurrency } from '../../utils/payment/display-currency';
import { FraudStatus } from '../../library/enums';
import { useIntercom } from 'react-use-intercom';

export default function ProfileCard(): ReactElement {
  const { user, spendingLimits } = useSelector((state: RootStateOrAny) => state.user);
  const { loading } = useUserProfile();
  const { show: showIntercomMessenger } = useIntercom();

  function getTrustStatusDisplayLabel(trustStatus: FraudStatus) {
    if (trustStatus === 'PASS') {
      return (
        <p className="avatar-text-sub">
          <span className="d-none d-sm-inline">Available to spend: </span>
          <Label className="fluz-gradient-green ml-1">
            {displayCurrency(Number(Number(spendingLimits?.limit?.limit) - Number(spendingLimits?.daily_spent)))}
          </Label>
        </p>
      );
    } else {
      return (
        <p
          className="avatar-text-sub"
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={showIntercomMessenger}
        >
          Contact support
          {['FAIL', 'LOCKED'].includes(trustStatus) ? (
            <Label className="label-error ml-1">{trustStatus}</Label>
          ) : trustStatus === 'PENDING_DOCUMENTS' ? (
            <Label className="label-info ml-1">Verification Required</Label>
          ) : (
            <Label className="label-warning ml-1">In Review</Label>
          )}
        </p>
      );
    }
  }

  if (loading) {
    return <CSpinner />;
  } else {
    return (
      <div className="d-flex">
        <div>
          <p className="avatar-text-primary">{user?.email_address}</p>
          {getTrustStatusDisplayLabel(user?.trust_status)}
        </div>

        <Avatar
          className="d-none d-sm-block"
          firstName={user?.first_name}
          lastName={user?.last_name}
          uri={user?.account?.account_avatar_url}
        />
      </div>
    );
  }
}
