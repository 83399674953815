import { CBreadcrumbRouter, CHeader, CToggler } from '@coreui/react';
import React, { ReactElement } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { usePrivateRoutes } from '../../navigation/_private_routes';
import { setSidebarDisplay } from '../../store/setting/actions';

export default function Breadcrumb(): ReactElement {
  const dispatch = useDispatch();
  const { privateRoutes } = usePrivateRoutes();
  const show = useSelector((state: RootStateOrAny) => state.setting.sidebarDisplay);

  const toggleSidebar = () => {
    const val: boolean | string = [true, 'responsive'].includes(show) ? false : 'responsive';
    dispatch(setSidebarDisplay(val));
  };

  const toggleSidebarMobile = () => {
    const val: boolean | string = [false, 'responsive'].includes(show) ? true : 'responsive';
    dispatch(setSidebarDisplay(val));
  };

  return (
    <CHeader fixed style={{ top: 60, marginBottom: 60, minHeight: 60 }}>
      <CToggler inHeader className="ml-3 d-lg-none" onClick={toggleSidebarMobile} />
      <CToggler inHeader className="ml-3 d-md-down-none" onClick={toggleSidebar} />
      <CBreadcrumbRouter className="border-0 c-subheader-nav m-0 px-0 px-md-3" routes={privateRoutes} />
    </CHeader>
  );
}
